import React from 'react';

import { Grid, Chip, Stack } from '@mui/material';

import resistanceGenesReference from './resistanceGenesReference.js'

function AMRPredictionRow(props) {
  const {
    prediction,
      amrPredictions,
    isDiagnosticView,
      showResistanceGenes,
      resistanceGenes
  } = props;

  let callColor = '#0081ff';
  let callText = 'Intermediate';
  if (prediction.attributes?.amr_call === 'R' || prediction.attributes?.call === 'R') {
    callColor = '#ff9800';
    callText = 'Resistant';
  } else if (prediction.attributes?.amr_call === 'S' || prediction.attributes?.call === 'S') {
    callColor = 'green';
    callText = 'Susceptible';
  } else if (prediction.resistanceGenes) {
    callColor = 'grey';
    callText = <small style={{fontWeight: 'normal'}}>Resistance Gene{prediction.resistanceGenes.length === 1 ? '' : 's'} Found</small>
  }

  let confidenceContent = null;
  if (!isDiagnosticView && prediction.attributes?.model_performance_label) {
    const confidenceColor = prediction.attributes.model_performance_label === 'M'
          ? 'grey' : 'grey';

    const confidenceText = prediction.attributes?.model_performance_label === 'M'
      ? 'R&D Model' : 'Qualified Model';

    confidenceContent = (
      <Grid item xs={12} sm={3}>
        <p>
          <b><span style={{ color: confidenceColor }}>{confidenceText}</span></b>
        </p>
      </Grid>
    );
  }

  let imputedFromContent = null;
  if (!isDiagnosticView && !prediction.attributes?.model_performance_label && !prediction.resistanceGenes) {
    imputedFromContent = (
      <Grid item xs={12} sm={3}>
        <p>
          <span style={{ color: 'grey' }}><small>Imputed from: <b>{amrPredictions[prediction.attributes.amr_model_prediction_id].attributes.model_drug}</b> prediction</small></span>
        </p>
      </Grid>
    );
  }

  let resistanceGeneContent = null;
  if (!isDiagnosticView && resistanceGenes && showResistanceGenes && !prediction.resistanceGenes) {
    resistanceGeneContent = (
        <Stack direction="row" spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
            {
                [...resistanceGenes]
                    .filter(item => resistanceGenesReference[item] && resistanceGenesReference[item].antibiotics.includes(prediction.attributes.model_drug || prediction.attributes.drug))
                    .map(item => (
                        <Chip size="small" label={resistanceGenesReference[item].display_name} color="primary" />
                     ))
            }
        </Stack>
    );
  }

  if (!isDiagnosticView && prediction.resistanceGenes && showResistanceGenes) {
    resistanceGeneContent = (
        <Stack direction="row" spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
            {
                prediction.resistanceGenes
                    .map(item => (
                        <Chip size="small" label={item} color="primary" />
                     ))
            }
        </Stack>
    );
  }

  return (
    <Grid
      container
      sx={{
        pl: 3, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid', borderLeft: '#bdbdbd 2px solid',
      }}
    >
      <Grid item xs={12} sm={isDiagnosticView ? 10 : 3} sx={{ textAlign: 'left' }}>
        <p><b>{prediction.attributes?.model_drug || prediction.attributes?.drug || prediction.drug}</b></p>
      </Grid>
      {
          prediction.resistanceGenes ? <Grid item xs={12} sm={2}></Grid> : null
      }
      <Grid item xs={12} sm={prediction.resistanceGenes ? 3 : 2}>
        <b><p style={{ color: callColor }}>{callText}</p></b>
      </Grid>
      {confidenceContent}
      {imputedFromContent}
      {resistanceGeneContent}
    </Grid>
  );
}

export default AMRPredictionRow;
