import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SmallTermsAcceptedStatusIndicator from './User/SmallTermsAcceptedStatusIndicator';

function Profile(props) {
    const {
        userData, 
        setUserData,
        organization, 
        openTermsDialog
    } = useOutletContext();

    const { getAccessTokenSilently } = useAuth0();

    const [organizationAttributesWithUsage, setOrganizationAttributesWithUsage] = useState(null);
    const [userAttributesWithEmail, setUserAttributesWithEmail] = useState(null);
    const [editingProfile, setEditingProfile] = useState(false);
    const [savingProfile, setSavingProfile] = useState(false);
    const [passwordResetRequested, setPasswordResetRequested] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false)
    const [name, setName] = useState(userData?.data.attributes.name)
    const [email, setEmail] = useState(null)

    const changePassword = useCallback(() => {
      setPasswordResetRequested(true)
      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          });

          return fetch(
            // TODO: set URL and Client ID as env variable
            `https://dayzerodiagnostics.us.auth0.com/dbconnections/change_password`,
            {
              method: 'POST',
              headers,
              body: JSON.stringify(
                {
                    client_id: "0r5DKIPfYO0iky7cqBCK8Y7sgBycEdC9",
                    email: userAttributesWithEmail.email,
                    connection: "Username-Password-Authentication"
                }
            )
            },
          )
        })
        .then(() => setPasswordResetSent(true))
        .catch((error) => console.error(error));

    }, [name, email, editingProfile]) // eslint-disable-line react-hooks/exhaustive-deps

    const saveProfileEdit = useCallback(() => {
      setSavingProfile(true)

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          });

          let attributes = {}
          if (name !== userData.data.attributes.name) {
            attributes["name"] = name
          }

          if (email !== userAttributesWithEmail.email) {
            attributes["email"] = email
          }

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userData.data.id}`,
            {
              method: 'PUT',
              headers,
              body: JSON.stringify(
                {
                    data: {
                        type: "user",
                        attributes: attributes
                    }

                }
            )
            },
          )
        })
        .then((res) => res.json())
        .then((response) => {
          setUserData(response)
          setSavingProfile(false)
          setEditingProfile(false)
        })
        .catch((error) => console.error(error));

    }, [name, email, editingProfile]) // eslint-disable-line react-hooks/exhaustive-deps

    const cancelProfileEdit = useCallback(() => {
      setName(userData.data.attributes.name)
      setEmail(userAttributesWithEmail.email)
      setEditingProfile(false)
    }, [editingProfile]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!userData || editingProfile) {
        return
      }

      setName(userData.data.attributes.name)
    }, [userData, editingProfile])

    useEffect(() => {
      if (!userAttributesWithEmail || editingProfile) {
        return
      }

      setEmail(userAttributesWithEmail.email)
    }, [userAttributesWithEmail, editingProfile])

    useEffect(() => {
      if (!userData) {
        return
      }

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userData.data.id}?extra_fields=email`,
            {
              method: 'GET',
              headers,
            },
          )
        })
        .then((res) => res.json())
        .then((response) => {
          setUserAttributesWithEmail(response.data.attributes)
        })
        .catch((error) => console.error(error));
    }, [getAccessTokenSilently, userData])

    useEffect(() => {
      if (!userData) {
        return
      }

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organization.id}?extra_fields=users_ct,users_ct_limit,uploaded_gigabases_current_month,uploaded_gigabases_current_month_limit`,
            {
              method: 'GET',
              headers,
            },
          )
        })
        .then((res) => res.json())
        .then((response) => {
          setOrganizationAttributesWithUsage(response.data.attributes)
        })
        .catch((error) => console.error(error));
    }, [getAccessTokenSilently, organization, userData])

    const canSaveProfile = (editingProfile && ((name !== userData.data.attributes.name) || (email !== userAttributesWithEmail.email)))
    const nameIsValid = editingProfile && new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,75}$/u).test(name)
    const emailIsValid = editingProfile && new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(email)
    return (
    <Grid container maxWidth="lg" spacing={2} sx={{ m: 'auto', mt: 1, paddingRight: '30px', paddingBottom: '30px' }}>
      <Grid item xs={12} sm={6}>
        <Paper elevation={2}>
          <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
            <p><b>ORGANIZATION</b></p>
          </Box>
          <Box sx={{ padding: '1px 10px 20px 10px' }}>
            <p>
              <b>{organization?.attributes.name} <small style={{color: "gray"}}>({organization?.attributes.label || '-'})</small></b>
            </p>
            <Stack direction="row" spacing={2}>
                <p><b>Created:</b> {organization ? new Date(organization.attributes.created_at).toLocaleDateString() : '-'}</p>
                <p><b>Last updated:</b> {organization ? new Date(organization.attributes.updated_at).toLocaleDateString() : '-'}</p>
            </Stack>
            <Divider sx={{margin: "20px 5px"}} />
            <p><b>Plan:</b> {organization?.attributes.plan_label || 'Unlimited'}</p>
            
            <p><b>Users:</b> {organizationAttributesWithUsage?.users_ct || '-'} of {organizationAttributesWithUsage?.users_ct_limit || '∞'} user limit</p>
            <p><b>Uploaded sequencing data</b>: {organizationAttributesWithUsage?.uploaded_gigabases_current_month.toLocaleString() || '-'} GB of {organizationAttributesWithUsage?.uploaded_gigabases_current_month_limit || '∞'} GB per month limit</p>
            {
              organizationAttributesWithUsage && organizationAttributesWithUsage.uploaded_gigabases_current_month_limit ? 
                (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress variant="determinate" value={(organizationAttributesWithUsage.uploaded_gigabases_current_month / organizationAttributesWithUsage.uploaded_gigabases_current_month_limit) * 100} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {`${Math.min(Math.round((organizationAttributesWithUsage.uploaded_gigabases_current_month / organizationAttributesWithUsage.uploaded_gigabases_current_month_limit) * 100), 100)}%`}
                      </Typography>
                    </Box>
                  </Box>
                ) : null
              }
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper elevation={2}>
          <Box sx={{ backgroundColor: '#eee', padding: '1px 0px 1px 10px' }}>
            <Grid container>
              <Grid item xs={6}>
                <p><b>PROFILE</b></p>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "right", padding: "10px"}}>
                {!editingProfile ? (
                  <Button disabled={!userData || !userAttributesWithEmail} variant="contained" size="small" color="primary" onClick={() => setEditingProfile(true)}>Edit</Button>
                ) : (
                  <>
                  <Button disabled={!canSaveProfile || savingProfile || !nameIsValid || !emailIsValid} variant="contained" size="small" color="success" onClick={saveProfileEdit} sx={{marginRight: "5px"}}>Save</Button>
                  <Button disabled={savingProfile} variant="contained" size="small" color="error" onClick={cancelProfileEdit}>Cancel</Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ padding: '1px 10px 20px 10px' }}>
            {editingProfile ? (
              <TextField error={!nameIsValid} fullWidth margin="normal" id="profile-name-update" label="Name" variant="standard" value={name} onChange={(event) => setName(event.target.value)} helperText={nameIsValid ? null : "Please provide a valid name"} />
            ) : (
            <p>
              <b>{name || '-'}</b>
            </p>
            )}
            {editingProfile ? (
              <TextField error={!emailIsValid} fullWidth margin="normal" id="profile-email-update" label="Email" variant="standard" value={email} onChange={(event) => setEmail(event.target.value)} sx={{paddingBottom: '20px'}} helperText={email !== userAttributesWithEmail.email ? (emailIsValid ? null : "Please provide a valid email address") : null} />
            ) : (
            <p>
              {email || '-'}
            </p>
            )}
            <Stack direction="row" spacing={2}>
                <p><b>Onboarded:</b> {userData ? new Date(userData.data.attributes.created_at).toLocaleDateString() : '-'}</p>
                <p><b>Last updated:</b> {userData ? new Date(userData.data.attributes.updated_at).toLocaleDateString() : '-'}</p>
            </Stack>
            {
              userData ? <SmallTermsAcceptedStatusIndicator openTermsDialog={openTermsDialog} canOpenTermsDialog={true} latestTermsAcceptedAt={userData.data.attributes.latest_terms_accepted_at} /> : <>&nbsp;</>
            }
            
            <Divider sx={{margin: "20px 5px"}} />
            <Box sx={{padding: "10px 20px 10px 20px", textAlign: "center"}}>
              {
                passwordResetSent ? (
                  <p style={{color: "green"}}><CheckCircleOutlineIcon sx={{marginBottom: "-6px"}} /> A password reset link has been sent to your email.</p>
                ) : (
                  <Button disabled={!userData || !userAttributesWithEmail || editingProfile || passwordResetRequested} fullWidth variant="contained" color="primary" onClick={changePassword}>Change Password</Button>
                )
              }
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
    );
}

export default Profile;
