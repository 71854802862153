import React from 'react';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function SmallSequencingPlatformIndicator(props) {
    const {
        platform
    } = props;

    if (!platform) {
        return <span>-</span>
    }

    const logo = platform === 'illumina' ? illuminaLogo : ontLogo
    return (
        <span>
            <img 
                src={logo} 
                alt="platform-logo" 
                height="20px" 
                style={{ marginBottom: '-5px' }}
            />
            {' '}
            {platform.toUpperCase()}
        </span>
    )
}

export default SmallSequencingPlatformIndicator;
