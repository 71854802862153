import React, {useState, useCallback, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

function AddSamplesFileListItem(props) {
    const {
        sampleName,
        file,
        deleteSample,
        activeRun,
        uploadingStarted,
        uploadingFinished,
        uploadFile,
        r2File,
        isIllumina,
        reloadSamples
    } = props;

    const { getAccessTokenSilently } = useAuth0();

    const [uploadingState, setUploadingState] = useState(null);

    useEffect(() => {
        if (uploadingState === 'creating_lib_seq') {
            return uploadingStarted()
        }

        if (uploadingState === 'uploading_file' || uploadingState === 'failed') {
            return uploadingFinished()
        }
    }, [uploadingState]) // eslint-disable-line react-hooks/exhaustive-deps

    const triggerUpload = useCallback((concatenatedFastqgzData) => {
        setUploadingState('uploading_file')
        reloadSamples(true)
        return uploadFile(concatenatedFastqgzData, file, r2File)
            .then(() => {
                setUploadingState('success')
            })

    }, [file, r2File, uploadFile, reloadSamples]);

    const createConcatenatedFastqgz = useCallback(libSeqID => {
        setUploadingState('creating_concatenated_fastqgz')
        return getAccessTokenSilently().then(accessToken => {
            const headers = new Headers({
              Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            });

            let extraFields = ["location_signed_upload_url"]
            if (isIllumina) {
                extraFields.push("location_r2_signed_upload_url")
            }

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${libSeqID}/concatenated_fastqgzs?extra_fields=${extraFields.join(',')}`,
            {
              method: 'POST',
              headers,
                body: JSON.stringify(
                    {
                        data: {
                            type: "concatenated_fastqgz",
                            attributes: {}
                        }

                    }
                )
            },
        )

        })
          .then((res) => {
              if(!res.ok) throw new Error(res.status);
              return res.json()
          })
          .then((response) => {
              return triggerUpload(response.data)
          })
          .catch(
              (error) => {
                console.error(error)
                setUploadingState('failed')
              }
            );
    }, [getAccessTokenSilently, triggerUpload, isIllumina]);

    const uploadSample = useCallback(() => {
        setUploadingState('creating_lib_seq')
        getAccessTokenSilently().then(accessToken => {
            const headers = new Headers({
              Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            });

            return fetch(
                `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${activeRun.id}/lib_seqs`,
                {
                  method: 'POST',
                  headers,
                    body: JSON.stringify(
                        {
                            data: {
                                type: "lib_seq",
                                attributes: {
                                    sample_type: "isolate",
                                    sample_name: sampleName,
                                    sample_id: sampleName,
                                    library_id: file?.lastModified || Date.now(),

                                }
                            }

                        }
                    )
                },
              )
           })
          .then((res) => {
              if(!res.ok) throw new Error(res.status);
              return res.json()
          })
          .then((response) => {
            const libSeqID = response.data.id
            return createConcatenatedFastqgz(libSeqID)
          })
          .catch(
              (error) => {
                console.error(error)
                setUploadingState('failed')
              }
            );
    }, [getAccessTokenSilently, sampleName, file, activeRun, createConcatenatedFastqgz])

    const totalFileSize = isIllumina ? ((file?.size | 0) + (r2File?.size | 0)) : file.size
    const i = totalFileSize === 0 ? 0 : Math.floor(Math.log(totalFileSize) / Math.log(1024));
    const humanReadableFileSize = ((totalFileSize / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];

    let icons = null;
    if (!uploadingState) {
        icons = (
            <>
            {(!isIllumina || (file !== null && r2File !== null)) ? (
            <Button sx={{marginTop: "5px", marginRight: "10px"}} variant="contained" color="primary" aria-label="upload" onClick={() => uploadSample(sampleName)} startIcon={<UploadIcon />}>Upload</Button> ) : null}
            <Button sx={{marginLeft: "5px", marginRight: "10px", marginTop: "5px"}} variant="outlined" color="error" aria-label="upload" onClick={() => deleteSample(sampleName)} endIcon={<DeleteIcon />}>DELETE</Button>
            </>
        )
    } else if (uploadingState === 'success') {
        icons = (
            <CheckCircleOutlineIcon fontSize="large" color="success" sx={{marginTop: "7px", marginRight: "30px"}} />
        )
    } else if (uploadingState === 'failed') {
        icons = (
            <ErrorOutlineIcon fontSize="large" color="warning" sx={{marginTop: "7px", marginRight: "30px"}} />
        )
    } else {
        const steps = {
            creating_lib_seq: 1,
            creating_concatenated_fastqgz: 2,
            uploading_file: 3
        }

        let progress = (steps[uploadingState] / 5) * 100
        icons = (
                <Box sx={{paddingTop: "10px"}}>
              <CircularProgress
                    sx={{marginRight: "35px"}}
                    size={27}
                    value={uploadingState === 'uploading_file' ? "" : progress}
                    variant={uploadingState === 'uploading_file'? "indeterminate" : "determinate"}
                  />
            </Box>
        )
    }

    return (
      <Box>
        <Grid
          container
          sx={{
              pl: 2, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid', fontSize: 15
          }}
        >
          <Grid item xs={12} sm={isIllumina ? 3 : 2} sx={{ textAlign: 'left' }}>
            <p style={{
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis'
            }}>
              <b>
                {sampleName}
              </b>
            </p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{file?.path || '-'}</p>
          </Grid>
        {
            isIllumina ? 
            (
              <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
                <p>{r2File?.path || '-'}</p>
              </Grid>
            ) : null

        }
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{ humanReadableFileSize }</p>
          </Grid>
        {
            isIllumina ? null : (
                  <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                    <p>{file.lastModifiedDate ? file.lastModifiedDate.toLocaleString() : '-'}</p>
                  </Grid>
            )
        }
          <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
            {icons}
          </Grid>
        </Grid>
      </Box>
    )
}

export default AddSamplesFileListItem;
