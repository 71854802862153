import React, {useState} from 'react';
import { Box, Grid, Divider, Link } from '@mui/material';

import IdentifiedSpeciesVersionDialog from '../Runs/IdentifiedSpeciesVersionDialog'

function DetailedSampleMetadataSummary(props) {
  const {
    sample,
    analysis
  } = props;

  const [versionDialogOpen, setVersionDialogOpen] = useState(false)

  let analysisExecutionTimeString = null;
    if (analysis) {
        const diffMs = new Date(analysis.attributes.updated_at) - new Date(analysis.attributes.created_at)
        const diffMins = Math.round(diffMs / 60000);
        analysisExecutionTimeString = `(${diffMins} min.)`
    }

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SAMPLE / ANALYSIS</b></p>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Sample Name:</b>
          {' '}
          {sample?.attributes.sample_name || sample?.attributes.sample_id || sample?.attributes.library_id || 'N/A'}
        </p>
        <p>
          <b>Sample Type:</b>
          {' '}
          {sample?.attributes.sample_type || 'Unknown'}
        </p>
        <p>
            <Divider />
            &nbsp;
        </p>
        <p><b>Analysis Version</b>: {analysis?.attributes.metadata.minion_pipeline_version ? (<Link onClick={() => setVersionDialogOpen(true)}sx={{'&:hover': {color: 'orange', cursor: 'pointer', textDecoration: 'underline'}}}>{analysis.attributes.metadata.minion_pipeline_version}</Link>) : '-'}</p>
        <p><b>Analysis Started At</b>: {analysis ? new Date(analysis.attributes.created_at).toLocaleString() : null}</p>
        <p><b>Analysis Completed At</b>: {analysis ? new Date(analysis.attributes.updated_at).toLocaleString() : null} <span style={{color: 'gray'}}>{analysisExecutionTimeString}</span></p>
      </Box>
      <IdentifiedSpeciesVersionDialog open={versionDialogOpen} setOpen={setVersionDialogOpen} analysis={analysis} />
    </>
  );
}

export default DetailedSampleMetadataSummary;
