import React from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

import SmallRunStatusIndicator from './SmallRunStatusIndicator';
import SmallSequencingPlatformIndicator from './SmallSequencingPlatformIndicator.jsx';

import {
  sequencerLabelToName,
  flowcellLabelToName,
  barcodingKitLabelToName,
  libraryPrepKitLabelToName
} from '../Runs/canonicalizeMetadata.js'

const dataGridStyle = {
  [`& .${gridClasses.cell}`]: { cursor: 'pointer' },
  [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
    outline: 'transparent',
  },
  [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
}

function DatasetsDataGrid(props) {
  const {
    datasets,
    datasetsGridRef,
    datasetsGridRows,
    datasetStatusRef,
    setActiveRun,
    setAddSamplesDialogOpen,
    setLoadStatuses
  } = props;

  const dataGridColumns = [
    {
      field: 'name',
      headerName: 'Dataset Name',
      minWidth: 300,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Dataset Name</b>
      ),
      renderCell: cell => (
        <b>{cell.value}</b>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Status</b>
      ),
      headerAlign: 'center',
      align: 'center',
      renderCell: cell => {
        // A datasetStatusRef is maintained to allow for lazy population of status information
        // without requiring reload of that status information if the datagrid is unmounted
        // Statuses from the datasetStatusRef can be used as a fallback if status is unavailable
        // directly from DataGrid data
        return <SmallRunStatusIndicator status={cell.value || datasetStatusRef[cell.row.id]} />
      }
    },
    {
      field: 'platform',
      headerName: 'Platform',
      width: 200,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Platform</b>
      ),
      headerAlign: 'center',
      align: 'center',
      renderCell: cell => <SmallSequencingPlatformIndicator platform={cell.value} />
    },
    {
      field: 'sequencer_name',
      headerName: 'Sequencer',
      width: 200,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Sequencer</b>
      ),
      valueGetter: value => {
        return sequencerLabelToName[value] || value
      },
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'flowcell',
      headerName: 'Flow Cell',
      width: 200,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Flow Cell</b>
      ),
      valueGetter: value => {
        return flowcellLabelToName[value] || value
      },
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'barcode_kit',
      headerName: 'Barcode/Index Kit',
      width: 200,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Barcode / Index Kit</b>
      ),
      valueGetter: value => {
        return barcodingKitLabelToName[value] || value
      },
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'library_prep_kit',
      headerName: 'Library Prep Kit',
      width: 200,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Library Prep Kit</b>
      ),
      valueGetter: value => {
        return libraryPrepKitLabelToName[value] || value
      },
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 300,
      resizable: false,
      headerClassName: 'keynome-table--header',
      renderHeader: value => (
        <b>Created At</b>
      ),
      headerAlign: 'center',
      align: 'center',
      type: 'dateTime'
    },
  ]

  return (
    <DataGrid
      autoPageSize
      columns={dataGridColumns}
      rows={datasetsGridRows}
      apiRef={datasetsGridRef}
      onRowClick={(params) => {
        setAddSamplesDialogOpen(true)
        setActiveRun(
          {
            'id': params.row.id,
            'attributes': {
              platform: params.row.platform,
              name: params.row.name,
              sequencer_name: params.row.sequencer_name,
              minion_sn: params.row.sequencer_sn
            }
          }
        )
      }}
      loading={!datasets}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      onPaginationModelChange={() => setLoadStatuses(true)}
      sx={dataGridStyle}
    />
  );
}

export default DatasetsDataGrid;
