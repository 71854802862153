import React from 'react';

import { Box } from '@mui/material';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function DetailedRunMetadataSummary(props) {
  const {
    run,
  } = props;

    const sequencerNameLabels = {
        p2: "P2",
        p24: "P24",
        gridion: "GridION",
        minion: "MinION",
        miseq: "MiSeq",
        nextseq_500: "NextSeq 500",
        nextseq_550: "NextSeq 550",
        hiseq_1000: "HiSeq 1000",
        hiseq_2000: "HiSeq 2000"
    }

    const flowCellNameLabels = {
        miseq: "MiSeq",
        nextseq_500_high_output: "NextSeq 500 High Output"
    }

    const barcodingKitLabels = {
        nextera_xt_v2: 'Nextera XT v2',
        nextera_xt_v3: 'Nextera XT v3'
    }

    const libraryPrepKitLabels = {
        nextera_xt_dna: 'Nextera XT DNA'
    }

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <p><b>SEQUENCING</b></p>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Run / Dataset Name:</b>
          {' '}
          {run?.attributes.name}
        </p>
        <p>
          <b>Sequencer:</b>
          {' '}
          <img src={run && (run.attributes.sequencing_platform === 'illumina' || run.attributes.platform === 'illumina') ? illuminaLogo : ontLogo} alt="platform-logo" height="15px" style={{marginBottom: '-2px'}} />
          {' '}
          {sequencerNameLabels[run?.attributes.sequencer_name] || run?.attributes.sequencer_name || 'Unknown'}
         {' '}
         <span style={{color: 'gray'}}>{run && run.attributes.minion_sn ? `(${run.attributes.minion_sn})` : null}</span>
        </p>
        <p>
          <b>Flow Cell:</b>
          {' '}
          {flowCellNameLabels[run?.attributes.flowcell_name] || run?.attributes.flowcell_name || 'Unknown'}
          {' '}
          <span style={{color: 'gray'}}>{run && run.attributes.flowcell_sn ? `(${run.attributes.flowcell_sn})` : null}</span>
        </p>
        <p>
          <b>Library Prep Kit:</b>
          {' '}
          {libraryPrepKitLabels[run?.attributes.library_prep_kit] || run?.attributes.library_prep_kit || 'Unknown'}
        </p>
        <p>
          <b>{(run?.attributes.sequencing_platform === 'illumina' || run?.attributes.platform === 'illumina') ? 'Index' : 'Barcoding'} Kit:</b>
          {' '}
          {barcodingKitLabels[run?.attributes.barcoding_kit] || run?.attributes.barcoding_kit || 'Unknown'}
        </p>
        <p>
          <b>Date:</b>
          {' '}
          {run ? new Date(run.attributes.created_at).toDateString() : null}
        </p>
      </Box>
    </>
  );
}

export default DetailedRunMetadataSummary;
