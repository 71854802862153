import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0, withAuth0 } from '@auth0/auth0-react';
import { useNavigate  } from "react-router-dom";

function UserDropdown() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

const navigate = useNavigate();

    const {
      pathname
    } = useLocation();

  const { user, isAuthenticated, logout } = useAuth0();
  const [userInfo, setUserInfo] = React.useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(user);
    }
  }, [isAuthenticated, user]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={userInfo?.name || ''} src={userInfo?.picture || "/static/images/avatar/2.jpg" } />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',

        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',

        }}
        open={Boolean(anchorElUser)}
        onClick={handleCloseUserMenu}
        onClose={handleCloseUserMenu}
      >
      {
        pathname !== "/profile" ? (
        <MenuItem key="profile" onClick={() => navigate('/profile')}>
            <Typography textAlign="center">Profile</Typography>
        </MenuItem>
        ) : (
        <MenuItem key="profile" onClick={() => navigate('/')}>
            <Typography textAlign="center">Dashboard</Typography>
        </MenuItem>
        )
      }
      <MenuItem key="logout" onClick={() => logout({logoutParams: {
            returnTo: window.location.origin
      }})}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default withAuth0(UserDropdown);
