const sequencerLabelToName = {
    minion: "MinION",
    gridion: "GridION",
    p2: "PromethION 2",
    p24: "PromethION 24",
    iseq_100: "iSeq 100",
    miniseq: "MiniSeq",
    miseq: "MiSeq",
    nextseq_500: "NextSeq 500",
    nextseq_550: "NextSeq 550",
    nextseq_1000: "NextSeq 1000",
    nextseq_2000: "NextSeq 2000",
    hiseq_1000: "HiSeq 1000",
    hiseq_2000: "HiSeq 2000",
    novaseq_6000: "NovaSeq 6000",
    novaseq_x: "NovaSeq X"
}

const flowcellLabelToName = {
    iseq_100: "iSeq 100",
    miniseq_high_output: "MiniSeq High Output",
    nextseq_500_high_output: "NextSeq 500 High Output",
    nextseq_500_mid_output: "NextSeq 500 Mid Output",
    nextseq_550_high_output: "NextSeq 550 High Output",
    nextseq_550_mid_output: "NextSeq 550 Mid Output",
    hiseq_3000: "HiSeq 3000",
    hiseq_4000: "HiSeq 4000",
    hiseq_x: "HiSeq X",
    novaseq_6000_s1: "NovaSeq 6000 S1",
    novaseq_6000_s2: "NovaSeq 6000 S2",
    novaseq_6000_s3: "NovaSeq 6000 S3",
}

const barcodingKitLabelToName = {
    nextera_xt_v2: 'Nextera XT v2',
    nextera_xt_v3: 'Nextera XT v3',
    nextera_dna_flex_v2: "Nextera DNA Flex v2",
    truseq_dna_pcr_free: "TruSeq DNA PCR-Free",
    truseq_dna_and_rna_single: "TruSeq DNA and RNA Single",
}

const libraryPrepKitLabelToName = {
    nextera_dna_flex: "Nextera DNA Flex",
    truseq_dna_pcr_free: "TruSeq DNA PCR-Free",
    truseq_dna_nano: "TruSeq DNA Nano",
    truseq_dna_flex: "TruSeq DNA Flex",
    nextera_xt_dna: "Nextera XT DNA",
}

export {
    sequencerLabelToName,
    flowcellLabelToName,
    barcodingKitLabelToName,
    libraryPrepKitLabelToName
}
