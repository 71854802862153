import React from 'react';

import {
  Container, Box, Grid, List,
} from '@mui/material';

import SampleInDatasetListItem from './SampleInDatasetListItem';

function SamplesList(props) {
  const {
    samples,
    selectedRun,
    activeProject,
  } = props;

  let samplesList;
  if (samples == null) {
    samplesList = (
      <Container sx={{ textAlign: 'center', paddingTop: '50px', paddingBottom: '50px' }}><p style={{'color': '#aaa'}}><b>Scan or enter a sample identifier</b></p></Container>
    );
  } else if (!samples.length) {
    samplesList = (
      <Container sx={{ textAlign: 'center', paddingBottom: '10px' }}><p>Could not find any samples matching your search value</p></Container>
    );
  } else {
    samplesList = samples.map((sample, idx) => (
      <SampleInDatasetListItem project={activeProject} sample={sample} idx={idx} selectedRun={selectedRun} />
    ));
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px', color: 'black' }}>
        <Grid container sx={{ pl: 1, pr: 1, textAlign: 'center' }}>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'left' }}>
            <p><b>Dataset</b></p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p><b>Sample Name</b></p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>Status</p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>Yield</p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>Species ID</p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>Processed On</p>
          </Grid>
        </Grid>
      </Box>
      <List sx={{
        width: '100%', bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0',
      }}
      >
        {samplesList}
      </List>
    </>
  );
}

export default SamplesList;
