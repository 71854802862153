import React from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

function SmallRunStatusIndicator(props) {
    const { status } = props

    let statusIcon = <PendingIcon height="20px" sx={{marginBottom: "-7px"}}/>
    let statusIconColor = 'gray'
    if (status) {
        statusIcon = <CloudUploadIcon height="20px" sx={{marginBottom: "-7px"}} />
        statusIconColor = 'gray'
        if (status === 'basecalling') {
            statusIcon = <BlurLinearIcon height="20px" sx={{marginBottom: "-7px"}} />
            statusIconColor = '#ed6c02'
        } else if (status === 'analyzing') {
            statusIcon = <LensBlurIcon height="20px" sx={{marginBottom: "-7px"}} />
            statusIconColor = '#ed6c02'
        } else if (status === 'delayed') {
            statusIcon = <WatchLaterIcon height="20px" sx={{marginBottom: "-7px"}} />
            statusIconColor = '#ed6c02'
        } else if (status === 'failed') {
            statusIcon = <ErrorIcon height="20px" sx={{marginBottom: "-7px"}} />
            statusIconColor = '#ed6c02'
        } else if (status === 'succeeded') {
            statusIcon = <CheckCircleIcon height="20px" sx={{marginBottom: "-7px"}} />
            statusIconColor = 'green'
        }
    }

    let statusText = ''
    if (status) {
        statusText = 'Uploading'
        if (status === 'basecalling') {
            statusText = 'Basecalling'
        } else if (status === 'analyzing') {
            statusText = 'Analyzing'
        } else if (status === 'delayed') {
            statusText = 'Delayed'
        } else if (status === 'failed') {
            statusText = 'Has Failures'
        } else if (status === 'succeeded') {
            statusText = 'Succeeded'
        }

    }

    return (
        <span style={{color: statusIconColor}}>
        {statusIcon}
        {'  '}
        {statusText}
        </span>
    )
}

export default SmallRunStatusIndicator;
