import React, {useMemo, useState, useCallback, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function slugify(str) {
      str = str.toLowerCase();
      str = str.replace(/[^a-z0-9 -]/g, ''); // Remove special characters
      str = str.replace(/\s+/g, '-'); // Replace spaces with hyphens
      return str;
}

function AddRunDialog(props) {
    const { open, setOpen, activeProject, userData, reloadRuns } = props;
    const { getAccessTokenSilently } = useAuth0();

    const randomChars = Math.random().toString(36).slice(1)
    const date = new Date().toISOString().split('T')[0]
    const defaultFriendlyName = `${date} Dataset ${randomChars}`
    const defaultName = slugify(defaultFriendlyName)

    const [isCreating, setIsCreating] = useState(false);
    const [platform, setPlatform] = useState('ont');
    const [friendlyName, setFriendlyName] = useState(defaultFriendlyName);
    const [name, setName] = useState(defaultName);
    const [sequencer, setSequencer] = useState('');
    const [sequencerSerialNumber, setSequencerSerialNumber] = useState('');
    const [flowCell, setFlowCell] = useState('');
    const [flowCellSerialNumber, setFlowCellSerialNumber] = useState('');
    const [barcodingKit, setBarcodingKit] = useState('');
    const [libraryPrepKit, setLibraryPrepKit] = useState('');
    const [formIsValid, setFormIsValid] = useState(true);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (sequencer !== '' && sequencer !== 'unknown' && sequencerSerialNumber !== '' && !(new RegExp("_?[A-Z0-9]{5,6}(-[A-Z0-9]{1,2})?$", "i").test(sequencerSerialNumber))) {
            setFormIsValid(false)
            return
        }

        if (flowCell !== '' && flowCell !== 'unknown' && flowCellSerialNumber !== '' && !(new RegExp("^[A-Z][0-9]{5}$", "i").test(flowCellSerialNumber))) {
            setFormIsValid(false)
            return
        }

        setFormIsValid(true)
    }, [flowCell, sequencer, flowCellSerialNumber, sequencerSerialNumber])

    useEffect(() => {
        const randomChars = Math.random().toString(36).slice(8).toUpperCase()

        const firstName = userData?.data.attributes.name
            .split(' ')[0]

        setFriendlyName(`${firstName}'s ${date} Dataset ${randomChars}`)
    }, [userData, date]);

    useEffect(() => {
        setName(slugify(friendlyName))
    }, [friendlyName]);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        setOpen(false);
    };

    const sequencerSNData = useMemo(() => {
        return {
            minion: {
                prefix: "MN",
                placeholder: "XXXXX"
            },
            gridion: {
                prefix: "GXB",
                placeholder: "XXXXX-XX"
            },
            p2: {
                prefix: "P2S",
                placeholder: "XXXXX-XX"
            },
            p24: {
                prefix: "PC",
                placeholder: "XXXXX-XX"
            }        
        }
    }, []);

    const createRun = useCallback(() => {
        setIsCreating(true)
        getAccessTokenSilently().then(accessToken => {
            const headers = new Headers({
              Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            });

            const attributes = {
                name: name,
                sequencing_platform: platform,
                sequencer_name: sequencer,
            }

            if (sequencer !== '' && sequencerSerialNumber !== '') {
                attributes['minion_sn'] = `${sequencerSNData[sequencer].prefix}${sequencerSerialNumber}`
            }
            
            if (flowCell !== '' && flowCell !== 'unknown' && flowCell !== 'other') {
                attributes['flowcell_name'] = flowCell
            }

            if (flowCell !== '' && flowCellSerialNumber !== '') {
                attributes['flowcell_serial_number'] = flowCellSerialNumber
            }

            if (barcodingKit !== '' && barcodingKit !== 'unknown' && barcodingKit !== 'other') {
                attributes['barcoding_kit'] = barcodingKit
            }

            if (libraryPrepKit !== '' && libraryPrepKit !== 'unknown' && libraryPrepKit !== 'other') {
                attributes['library_prep_kit'] = libraryPrepKit
            }

            if (description !== '') {
                attributes['description'] = description
            }

            return fetch(
                `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/sequencing_runs`,
                {
                  method: 'POST',
                  headers,
                    body: JSON.stringify(
                        {
                            data: {
                                type: "sequencing_run",
                                attributes: attributes
                            }
                        }
                    )
                },
              )
           })
          .then((res) => {
              if(!res.ok) throw new Error(res.status);
              reloadRuns()
              setIsCreating(false)
              setOpen(false)
          })
          .catch(
              (error) => {
                console.error(error)
              }
            );
    }, [
        getAccessTokenSilently,
        name,
        sequencerSerialNumber,
        flowCell,
        flowCellSerialNumber,
        barcodingKit,
        libraryPrepKit,
        activeProject,
        platform,
        reloadRuns,
        sequencer,
        setOpen,
        sequencerSNData,
        description
    ])

    let formContent = null
    if (platform === 'ont') {
        formContent = (
            <>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="sequencer-select-label">Sequencer</InputLabel>
                    <Select
                        fullWidth
                        labelId="sequencer-select-label"
                        id="sequencer-select"
                        value={sequencer}
                        label="Sequencer"
                        onChange={(event) => setSequencer(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='minion'>MinION</MenuItem>
                        <MenuItem value='gridion'>GridION</MenuItem>
                        <MenuItem value='p2'>P2 / P2 Solo</MenuItem>
                        <MenuItem value='p24'>P24</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 5px"}}>
                <TextField
                    error={(sequencer === '' || sequencer === 'unknown' || sequencerSerialNumber === '') ? null : !(new RegExp("_?[A-Z0-9]{5,6}(-[A-Z0-9]{1,2})?$", "i").test(sequencerSerialNumber))} 
                    placeholder={sequencerSNData[sequencer] ? sequencerSNData[sequencer].placeholder : null}
                    helperText={(sequencer === '' || sequencer === 'unknown') ? null : "Optional"}
                    variant="standard"
                    fullWidth
                    label="Serial Number"
                    value={sequencerSerialNumber}
                    onChange={(event) => {
                        setSequencerSerialNumber(event.target.value.toUpperCase());
                    }}
                    disabled={sequencer === '' || sequencer === 'unknown'}
            slotProps={{
                input: {
                    startAdornment: <InputAdornment position="start">{sequencerSNData[sequencer] ? sequencerSNData[sequencer].prefix : null}</InputAdornment>
                }
            }}
                />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="flow-cell-select-label">Flow Cell</InputLabel>
                    <Select
                        fullWidth
                        labelId="flow-cell-select-label"
                        id="flow-cell-select"
                        value={flowCell}
                        label="Flow Cell"
                        onChange={(event) => setFlowCell(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='FLO-MIN106'>FLO-MIN106</MenuItem>
                        <MenuItem value='FLO-MIN107'>FLO-MIN107</MenuItem>
                        <MenuItem value='FLO-MIN110'>FLO-MIN110</MenuItem>
                        <MenuItem value='FLO-MIN114'>FLO-MIN114</MenuItem>
                        <MenuItem value='FLO-PRO002'>FLO-PRO002</MenuItem>
                        <MenuItem value='FLO-PRO114M'>FLO-PRO114M</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 5px"}}>
                <TextField
                    error={(flowCell === '' || flowCell === 'unknown' || flowCellSerialNumber === '') ? null : !(new RegExp("^[A-Z][0-9]{5}$", "i").test(flowCellSerialNumber))} 
                    placeholder={(flowCell === '' || flowCell === 'unknown') ? null : 'X12345'}
                    helperText={(flowCell === '' || flowCell === 'unknown') ? null : "Optional"}
                    variant="standard"
                    fullWidth
                    label="Serial Number"
                    value={flowCellSerialNumber}
                    disabled={flowCell === '' || flowCell === 'unknown'}
            slotProps={{
                    input: {
                        startAdornment: <InputAdornment position="start">{(flowCell === '' || flowCell === 'unknown') ? null : (flowCell.includes("PRO") ? 'PA' : 'FA')}</InputAdornment>
                    }
            }}
                    onChange={(event) => {
                        setFlowCellSerialNumber(event.target.value.toUpperCase());
                    }}
                />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="barcoding-kit-label">Barcoding Kit</InputLabel>
                    <Select
                        fullWidth
                        labelId="barcoding-kit-label"
                        id="barcoding-kit-select"
                        value={barcodingKit}
                        label="Barcoding Kit"
                        onChange={(event) => setBarcodingKit(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='EXP-PBC096'>EXP-PBC096</MenuItem>
                        <MenuItem value='SQK-RBK004'>SQK-RBK004</MenuItem>
                        <MenuItem value='SQK-RBK110.96'>SQK-RBK110.96</MenuItem>
                        <MenuItem value='SQK-RBK114.24'>SQK-RBK114.24</MenuItem>
                        <MenuItem value='SQK-RBK114.96'>SQK-RBK114.96</MenuItem>
                        <MenuItem value='SQK-RPB004'>SQK-RPB004</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="prep-kit-label">Library Prep Kit</InputLabel>
                    <Select
                        fullWidth
                        labelId="prep-kit-label"
                        id="prep-kit-select"
                        value={libraryPrepKit}
                        label="Library Prep Kit"
                        onChange={(event) => setLibraryPrepKit(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='SQK-LSK109'>SQK-LSK109</MenuItem>
                        <MenuItem value='SQK-LSK110'>SQK-LSK110</MenuItem>
                        <MenuItem value='SQK-LSK114'>SQK-LSK114</MenuItem>
                        <MenuItem value='SQK-RBK004'>SQK-RBK004</MenuItem>
                        <MenuItem value='SQK-RBK110.96'>SQK-RBK110.96</MenuItem>
                        <MenuItem value='SQK-RBK114.24'>SQK-RBK114.24</MenuItem>
                        <MenuItem value='SQK-RBK114.96'>SQK-RBK114.96</MenuItem>
                        <MenuItem value='SQK-RPB004'>SQK-RPB004</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{padding: "15px 10px"}}>
                    <TextField
                      id="description-textarea"
                      label="Description"
                      placeholder="Short description of sequencing dataset"
                      value={description}    
                      helperText="Optional"
                        variant="standard"
                      multiline
                      fullWidth
                      maxRows={2}
                    onChange={(event) => {
                        setDescription(event.target.value.slice(0, 100));
                    }}
                    />
                </Box>
            </Grid>
            </>
        )
    } else {
        formContent = (
            <>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="sequencer-select-label">Sequencer</InputLabel>
                    <Select
                        fullWidth
                        labelId="sequencer-select-label"
                        id="sequencer-select"
                        value={sequencer}
                        label="Sequencer"
                        onChange={(event) => setSequencer(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='iseq_100'>iSeq 100</MenuItem>
                        <MenuItem value='miniseq'>MiniSeq</MenuItem>
                        <MenuItem value='miseq'>MiSeq</MenuItem>
                        <MenuItem value='hiseq_1000'>HiSeq 1000</MenuItem>
                        <MenuItem value='hiseq_2000'>HiSeq 2000</MenuItem>
                        <MenuItem value='nextseq_500'>NextSeq 500</MenuItem>
                        <MenuItem value='nextseq_550'>NextSeq 550</MenuItem>
                        <MenuItem value='nextseq_1000'>NextSeq 1000</MenuItem>
                        <MenuItem value='nextseq_2000'>NextSeq 2000</MenuItem>
                        <MenuItem value='novaseq_6000'>NovaSeq 6000</MenuItem>
                        <MenuItem value='novaseq_x'>NovaSeq X</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="flow-cell-select-label">Flow Cell</InputLabel>
                    <Select
                        fullWidth
                        labelId="flow-cell-select-label"
                        id="flow-cell-select"
                        value={flowCell}
                        label="Flow Cell"
                        onChange={(event) => setFlowCell(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='iseq_100'>iSeq 100</MenuItem>
                        <MenuItem value='miniseq_high_output'>MiniSeq High Output</MenuItem>
                        <MenuItem value='nextseq_500_high_output'>NextSeq 500 High Output</MenuItem>
                        <MenuItem value='nextseq_500_mid_output'>NextSeq 500 Mid Output</MenuItem>
                        <MenuItem value='nextseq_550_high_output'>NextSeq 550 High Output</MenuItem>
                        <MenuItem value='nextseq_550_mid_output'>NextSeq 550 Mid Output</MenuItem>
                        <MenuItem value='hiseq_3000'>HiSeq 3000</MenuItem>
                        <MenuItem value='hiseq_4000'>HiSeq 4000</MenuItem>
                        <MenuItem value='hiseq_x'>HiSeq X</MenuItem>
                        <MenuItem value='novaseq_6000_s1'>NovaSeq 6000 S1</MenuItem>
                        <MenuItem value='novaseq_6000_s2'>NovaSeq 6000 S2</MenuItem>
                        <MenuItem value='novaseq_6000_s3'>NovaSeq 6000 S3</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="barcoding-kit-label">{platform === 'illumina' ? 'Index' : 'Barcoding'} Kit</InputLabel>
                    <Select
                        fullWidth
                        labelId="barcoding-kit-label"
                        id="barcoding-kit-select"
                        value={barcodingKit}
                        label="Barcoding Kit"
                        onChange={(event) => setBarcodingKit(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='nextera_xt_v2'>Nextera XT v2</MenuItem>
                        <MenuItem value='nextera_xt_v3'>Nextera XT v3</MenuItem>
                        <MenuItem value='nextera_dna_flex_v2'>Nextera DNA Flex v2</MenuItem>
                        <MenuItem value='truseq_dna_pcr_free'>TruSeq DNA PCR-Free</MenuItem>
                        <MenuItem value='truseq_dna_and_rna_single'>TruSeq DNA and RNA Single</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{padding: "15px 10px"}}>
                    <FormControl variant="standard" fullWidth>
                    <InputLabel id="prep-kit-label">Library Prep Kit</InputLabel>
                    <Select
                        fullWidth
                        labelId="prep-kit-label"
                        id="prep-kit-select"
                        value={libraryPrepKit}
                        label="Library Prep Kit"
                        onChange={(event) => setLibraryPrepKit(event.target.value)}
                    >
                        <MenuItem value='unknown'>Unknown</MenuItem>
                        <MenuItem value='nextera_dna_flex'>Nextera DNA Flex</MenuItem>
                        <MenuItem value='truseq_dna_pcr_free'>TruSeq DNA PCR-Free</MenuItem>
                        <MenuItem value='truseq_dna_nano'>TruSeq DNA Nano</MenuItem>
                        <MenuItem value='truseq_dna_flex'>TruSeq DNA Flex</MenuItem>
                        <MenuItem value='nextera_xt_dna'>Nextera XT DNA</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </Select>
                    <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{padding: "50px 10px"}}>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{padding: "15px 10px"}}>
                    <TextField
                      id="description-textarea"
                      label="Description"
                      placeholder="Short description of sequencing dataset"
                      value={description}    
                      helperText="Optional"
                        variant="standard"
                      multiline
                      fullWidth
                      maxRows={2}
                    onChange={(event) => {
                        setDescription(event.target.value.slice(0, 100));
                    }}
                    />
                </Box>
            </Grid>
            </>
        )
    }

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        >
            <DialogTitle>Add New Sequencing Dataset</DialogTitle>
            <DialogContent>
               <Box sx={{paddingTop: "10px"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{padding: "0px 10px"}}>
                    <ToggleButtonGroup
                        fullWidth
                      color="primary"
                      value={platform}
                      exclusive
                      onChange={(event, newPlatform) => {
                          if (newPlatform !== null) {
                              setPlatform(newPlatform)
                          }  
                      }}
                      aria-label="Platform"
                    >
                      <ToggleButton value="ont" sx={{fontSize: "15px", fontWeight: "bold"}}>
                        <img src={ontLogo} alt="platform-logo" height="20px" style={{marginLeft: '4px', marginRight: '10px'}} />
                        ONT
                    </ToggleButton>
                      <ToggleButton value="illumina" sx={{fontSize: "15px", fontWeight: "bold"}}>
                        <img src={illuminaLogo} alt="platform-logo" height="20px" style={{marginLeft: '4px', marginRight: '10px'}} />
                        Illumina
                        </ToggleButton>
                    </ToggleButtonGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{padding: '15px 5px'}}>
                            <Box sx={{padding: "5px"}}>
                            <TextField
                                required
                                fullWidth
                                variant="filled"
                                label="Name"
                                value={friendlyName}
                                helperText={`Required. ${friendlyName === '' ? '' : `Identifier: ${name}`}`}
                                onChange={(event) => {
                            setFriendlyName(event.target.value);

                        }}
                            />
                            </Box>
                        </Grid>
                        {formContent}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{marginBottom: "15px"}}>
                <Button variant="contained" color="primary" sx={{marginRight: "20px"}} onClick={createRun} disabled={(name === '' || isCreating || !formIsValid)}>Create</Button>
                <Button color="primary" sx={{marginRight: "20px"}} onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );

}


export default AddRunDialog;
