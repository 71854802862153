import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SmallTermsAcceptedStatusIndicator(props) {
    const {
        openTermsDialog,
        latestTermsAcceptedAt,
        canOpenTermsDialog
    } = props;

    return (
        <p>
            <CheckCircleIcon height="20px" sx={{marginBottom: "-7px", color: "green"}} /> 
            &nbsp;
            <span onClick={canOpenTermsDialog ? openTermsDialog : null} style={canOpenTermsDialog ? {color: "orange", cursor: "pointer", textDecoration: "underline"} : null}>
                <b>Terms of Use</b>
            </span> 
            &nbsp;
            accepted <b>{latestTermsAcceptedAt ? new Date(latestTermsAcceptedAt).toLocaleString() : '-'}</b>
        </p>
    );
}

export default SmallTermsAcceptedStatusIndicator;