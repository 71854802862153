import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import RunStatus from './RunStatus'
import RunStatusSkeleton from './RunStatusSkeleton'


function RunStatusList(props) {
    const {
        runs,
        activeRun,
        setActiveRun,
        setSamples
    } = props

    if (runs.every(run => run === 1)) {
        return (
            <Grid item xs={12}>
                <Box
                    width="100%"
                    height={155} 
                    sx={{textAlign: 'center', padding: '10px', paddingTop: '50px'}}
                >
                    <p 
                        style={{ color: 'grey' }}
                    >
                        <b>Recently viewed datasets will appear here</b>
                    </p>
                </Box>
            </Grid>
        )
    }

    return runs.map((run, idx) => {
        if (!run) {
            return <RunStatusSkeleton key={idx} />
        }

        if (run === 1) {
            return (
                <Grid item xs={12} sm={4} key={idx}>
                    <Box width="100%" height={184} sx={{padding: '10px'}}>
                    </Box>
                </Grid>
            )
        }

        return (
            <RunStatus
                key={idx}
                handleClick={(clickedRun) => {
                    setActiveRun(clickedRun)
                    setSamples(null)
                }}
                activeRun={activeRun}
                id={run.id}
                name={run.attributes.name}
                sequencingPlatformAttribute={run.attributes.sequencing_platform}
                createdAtAttribute={run.attributes.created_at}
                sequencerNameAttribute={run.attributes.sequencer_name}
                sequencerSNAttribute={run.attributes.minion_sn}
            />
        )
    })

}

export default RunStatusList;
