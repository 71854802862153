import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { DataGrid, gridClasses } from '@mui/x-data-grid';

function IdentifiedSpeciesVersionDialog(props) {
    const {
        open,
        setOpen,
        analysis
    } = props;

    let gASTVersion = 'v0.7.6.0'
    let clsiVersion = 'CLSI M100 Ed34 [2024]'
    let microhmDBVersion = 'v0.13.0'
    if (analysis?.attributes.metadata.minion_pipeline_version < '2024.08.05.a') {
        gASTVersion = 'v0.7.5.1'
        clsiVersion = 'CLSI M100 Ed32 [2022]'
        microhmDBVersion = 'v0.1.0'
    }

    const qualifiedModels = [
        {
            id: 1,
            "organism": "Pseudomonas aeruginosa group",
            "qualified_antibiotics": [],
            "rnd_antibiotics": [
                "amikacin",
                "ciprofloxacin",
                "tobramycin",
            ]
        },
        {
            id: 3,
            "organism": "Morganella morganii",
            "qualified_antibiotics": [],
            "rnd_antibiotics": [
                "ciprofloxacin",
                "levofloxacin",
                "tetracycline",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 4,
            "organism": "Citrobacter freundii complex",
            "qualified_antibiotics": [],
            "rnd_antibiotics": [
                "tetracycline"
            ]
        },
        {
            id: 5,
            "organism": "Enterobacter cloacae complex",
            "qualified_antibiotics": [],
            "rnd_antibiotics": [
                "cefuroxime",
                "ciprofloxacin",
                "gentamicin",
                "levofloxacin",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 6,
            "organism": "Acinetobacter calcoaceticus/baumannii complex",
            "qualified_antibiotics": [
                "amikacin",
                "ciprofloxacin",
                "gentamicin",
                "imipenem",
                "meropenem"
            ],
            "rnd_antibiotics": [
                "cefepime",
                "ceftazidime",
                "colistin",
                "levofloxacin",
                "piperacillin/tazobactam",
                "tetracycline",
                "tobramycin",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 7,
            "organism": "Klebsiella pneumoniae",
            "qualified_antibiotics": [
                "levofloxacin",
                "imipenem",
                "gentamicin",
                "ertapenem",
                "ciprofloxacin",
                "ceftriaxone",
                "aztreonam",
                "tobramycin"
            ],
            "rnd_antibiotics": [
                "amikacin",
                "amoxicillin/clavulanate",
                "ampicillin/sulbactam",
                "cefepime",
                "cefotaxime",
                "ceftazidime",
                "cefuroxime",
                "doripenem",
                "meropenem",
                "moxifloxacin",
                "nalidixic acid",
                "tetracycline",
                "ticarcillin/clavulanate",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 8,
            "organism": "Escherichia coli",
            "qualified_antibiotics": [
                "trimethoprim/sulfamethoxazole",
                "tetracycline",
                "levofloxacin",
                "gentamicin",
                "ciprofloxacin",
                "cefuroxime",
                "ceftriaxone",
                "ceftazidime",
                "cefepime",
                "cefazolin",
                "aztreonam",
                "ampicillin",
            ],
            "rnd_antibiotics": [
                "amoxicillin",
                "ampicillin/sulbactam",
                "cefotaxime",
                "ertapenem",
                "imipenem",
                "tobramycin",
            ]
        },
        {
            id: 9,
            "organism": "Enterococcus faecium",
            "qualified_antibiotics": [
                "vancomycin",
                "tetracycline",
                "levofloxacin"
            ],
            "rnd_antibiotics": [
                "ampicillin",
                "ciprofloxacin",
                "daptomycin",
                "doxycycline",
            ]
        },
        {
            id: 10,
            "organism": "Proteus mirabilis",
            "qualified_antibiotics": [
                "ciprofloxacin",
                "levofloxacin"
            ],
            "rnd_antibiotics": [
                "ampicillin",
                "ampicillin/sulbactam",
                "gentamicin",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 11,
            "organism": "Staphylococcus aureus",
            "qualified_antibiotics": [
                "ciprofloxacin",
                "tetracycline",
                "oxacillin",
                "moxifloxacin",
                "levofloxacin",
                "gentamicin",
                "erythromycin",
                "clindamycin",
            ],
            "rnd_antibiotics": [
                "cefoxitin",
                "doxycycline",
                "methicillin",
                "rifampicin",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 12,
            "organism": "Streptococcus pneumoniae",
            "qualified_antibiotics": [
                "erythromycin",
                "clarithromycin",
                "trimethoprim/sulfamethoxazole"
            ],
            "rnd_antibiotics": [
                "cefuroxime",
                "clindamycin",
                "doxycycline",
                "meropenem",
                "tetracycline",
            ]
        },
        {
            id: 13,
            "organism": "Streptococcus agalactiae",
            "qualified_antibiotics": [
                "erythromycin",
                "clindamycin",
            ],
            "rnd_antibiotics": []
        },
        {
            id: 14,
            "organism": "Staphylococcus hominis",
            "qualified_antibiotics": [
                "ciprofloxacin"
            ],
            "rnd_antibiotics": [
                "erythromycin",
                "moxifloxacin",
                "tetracycline",
            ]
        },
        {
            id: 15,
            "organism": "Staphylococcus epidermidis",
            "qualified_antibiotics": [
                "tetracycline",
                "oxacillin",
                "moxifloxacin",
                "levofloxacin",
                "gentamicin",
                "erythromycin",
                "doxycycline",
                "clindamycin",
                "ciprofloxacin",
            ],
            "rnd_antibiotics": [
                "rifampicin",
                "trimethoprim/sulfamethoxazole",
            ]
        },
        {
            id: 16,
            "organism": "Enterococcus faecalis",
            "qualified_antibiotics": [
                "tetracycline",
                "erythromycin",
                "gentamicin high level",
                "levofloxacin",
                "ciprofloxacin"
            ],
            "rnd_antibiotics": [
                "minocycline",
                "vancomycin",
            ]
        },
        {
            id: 17,
            "organism": "Staphylococcus lugdunensis",
            "qualified_antibiotics": [],
            "rnd_antibiotics": [
                "erythromycin",
            ]
        }
    ]

    const dataGridColumns = [
        {
            field: 'organism',
            headerName: 'Organism',
            minWidth: 300,
            flex: 0.75,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Organism</b>
            ),
            renderCell: cell => (
                <b>{cell.value}</b>
            )
        },
        {
            field: 'qualified_antibiotics',
            headerName: 'Qualified',
            minWidth: 300,
            flex: 1,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Qualified</b>
            ),
            valueGetter: value => {
                return value.sort().join(', ')
            }    
        },
        {
            field: 'rnd_antibiotics',
            headerName: 'R&D',
            minWidth: 300,
            flex: 1,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>RnD</b>
            ),
            valueGetter: value => {
                return value.sort().join(', ')
            }
        },
    ]

    return (
        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
        scroll="paper"
        >
            <DialogTitle sx={{backgroundColor: "#eee"}}>Analysis Version {analysis?.attributes.metadata.minion_pipeline_version}</DialogTitle>
            <DialogContent>
               <Box sx={{padding: "10px"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <p><b>Keynome ID: v3.0</b> <span style={{color: "gray"}}>(MicroRefDB v2.2.1)</span></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Keynome <i>g</i>AST: {gASTVersion}</b> <span style={{color: "gray"}}>(MicrohmDB {microhmDBVersion} / {clsiVersion})</span></p>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'grid', '& .runs-table--header': { backgroundColor: "#eee" }}}>
                            <p><b>Keynome <i>g</i>AST Panel</b></p>
                            <p style={{margin: "0 0 20px", color: "gray"}}><small><b>Supporting resistance genes reported:</b>&nbsp;
                            {
                                [
                                    "blaIMP",
                                    "blaKPC",
                                    "blaOXA-48 family",
                                    "blaOXA-23 family",
                                    "blaNDM",
                                    "blaVIM",
                                    "mcr-1",
                                    "blaCTX-M",
                                    "mecA",
                                    "mecC",
                                    "vanA",
                                    "vanB"
                                ].sort().join(', ')
                            }
                            </small></p>
                            <DataGrid
                                disableColumnSorting
                                autoHeight
                                columns={dataGridColumns}
                                rows={qualifiedModels.sort((a, b) => a.organism.localeCompare(b.organism))} 
                                getRowHeight={() => 'auto'} 
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 0.5,

                                    },

                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{backgroundColor: "#eee"}}>
                <Button color="primary" sx={{marginRight: "20px"}} onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );

}


export default IdentifiedSpeciesVersionDialog;
